import { RootStateOrAny } from 'react-redux';
import Link from 'next/link';
import Icon from '@mdi/react';

import { Avatar, Card, Col, Row, Space } from 'antd';

import { validateAccess } from '@utils/functions';
import { useAppSelector } from '@app/hooks';
import { RolesEnum } from '@features/auth/ts/enums';

interface Link {
  dir: string;
  name: string;
  roles?: RolesEnum[];
}

interface Props {
  title: string;
  links: Link[];
  path: string;
  color: string;
  style?: React.CSSProperties;
  classCss: string;
}

const CardHome: React.FC<Props> = (props: Props) => {
  const { roles } = useAppSelector((state: RootStateOrAny) => state?.auth?.user);

  return (
    <Space size="middle">
      <Card className={props.classCss}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={4}>
            <Avatar className="avatar-card" style={props.style} shape="square" size={44}>
              <Icon path={props.path} color={props.color} className="icon-card" />
            </Avatar>
          </Col>
          <Col span={18}>
            <p className="title-card" data-cy={`home-card-title-${props.title.toLowerCase()}`}>
              {props.title}
            </p>
            <div className="card-links">
              {props.links.map((link) =>
                validateAccess(roles, link.roles) ? (
                  <Link href={link.dir} key={link.name}>
                    <a data-cy={`home-card-link-${link.name.toLowerCase().replace(' ', '-')}`}>
                      {link.name}
                    </a>
                  </Link>
                ) : undefined
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </Space>
  );
};

export default CardHome;
